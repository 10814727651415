import React from 'react';
import PropTypes from 'prop-types';
import useGame from '../../../contexts/useGame';

function EnhancedMarkdownTwitterLink({ children }) {
  const game = useGame();
  const account = ['warcraft', 'ff'].includes(game.lowercaseName)
    ? `${game.lowercaseName}logs`
    : `logs${game.lowercaseName}`;
  const link = `https://twitter.com/${account}`;

  return (
    <a href={link} target={'_blank'} rel={'noreferrer noopener'}>
      {children ?? 'Twitter'}
    </a>
  );
}
EnhancedMarkdownTwitterLink.propTypes = {
  children: PropTypes.node,
};

export default EnhancedMarkdownTwitterLink;
