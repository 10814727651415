import React, { ReactNode } from 'react';
import Async, { AsyncProps } from 'react-async';
import ErrorBox from '../ErrorBox/ErrorBox';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import useStateThatChangesAfterTimeout from '../../hooks/useStateThatChangesAfterTimeout';

type DefaultLoadingStateProps = {
  height?: number;
  timeoutDuration?: number;
};

export const DefaultLoadingState = ({
  height = 250,
  timeoutDuration = 200,
}: DefaultLoadingStateProps): JSX.Element => {
  const isVisible = useStateThatChangesAfterTimeout(
    false,
    timeoutDuration,
    true
  );

  return (
    <div
      className={'async-with-spinner-and-error-box__pending'}
      style={{ minHeight: height + 'px' }}
    >
      {isVisible && <LoadingSpinner label={trans('reports.loading_data')} />}
    </div>
  );
};

type DefaultErrorBoxProps = {
  height: number;
};

export const DefaultErrorBox = ({
  height,
}: DefaultErrorBoxProps): JSX.Element => (
  <div
    className={'async-with-spinner-and-error-box__error'}
    style={{ minHeight: height + 'px' }}
  >
    <ErrorBox>{trans('global.generic_load_error')}</ErrorBox>
  </div>
);

export type AsyncWithSpinnerAndErrorBoxProps<T> = {
  pendingOrRejectedHeight?: number;
  LoadingState?: (() => ReactNode) | ReactNode;
  initialRenderDelayWhileLoading?: number;
  lazyChildren: (data: T) => ReactNode;
  promiseFn: AsyncProps<T>['promiseFn'];
  params?: AsyncProps<T>['params'];
};

function AsyncWithSpinnerAndErrorBox<T>({
  promiseFn,
  params,
  pendingOrRejectedHeight = 250,
  lazyChildren,
  LoadingState,
  initialRenderDelayWhileLoading,
}: AsyncWithSpinnerAndErrorBoxProps<T>): JSX.Element {
  return (
    <Async promiseFn={promiseFn} {...params}>
      <Async.Pending>
        {LoadingState === undefined
          ? () => (
              <DefaultLoadingState
                height={pendingOrRejectedHeight}
                timeoutDuration={initialRenderDelayWhileLoading}
              />
            )
          : LoadingState}
      </Async.Pending>
      <Async.Rejected>
        <DefaultErrorBox height={pendingOrRejectedHeight} />
      </Async.Rejected>
      <Async.Fulfilled<T>>{(data) => lazyChildren(data)}</Async.Fulfilled>
    </Async>
  );
}

export default AsyncWithSpinnerAndErrorBox;
