import React from 'react';
import Tile from '../../Tile/Tile';
import PropTypes from 'prop-types';
import Body from '../../Body/Body';

function EnhancedMarkdownTile(props) {
  return (
    <Tile style={'flat'} {...props}>
      <Body>{props.children}</Body>
    </Tile>
  );
}
EnhancedMarkdownTile.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EnhancedMarkdownTile;
