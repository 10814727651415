import useGame from '../../../contexts/useGame';
import React from 'react';
import PropTypes from 'prop-types';

function shouldRender(
  {
    warcraft,
    retailWarcraft,
    classicWarcraft,
    vanillaWarcraft,
    sodWarcraft,
    ff,
    eso,
    swtor,
  },
  game
) {
  if (warcraft && game.id === 0) return true;

  if (retailWarcraft && game.id === 0 && game.area === 1) return true;

  if (classicWarcraft && game.id === 0 && game.area === 2) return true;

  if (vanillaWarcraft && game.id === 0 && game.area === 3) return true;

  if (sodWarcraft && game.id === 0 && game.area === 3 && game.season === 3)
    return true;

  if (ff && game.id === 2) return true;

  if (eso && game.id === 4) return true;

  if (swtor && game.id === 5) return true;
}

function EnhancedMarkdownIf(props) {
  const game = useGame();

  if (shouldRender(props, game)) return <>{props.children}</>;

  return null;
}
EnhancedMarkdownIf.propTypes = {
  children: PropTypes.node.isRequired,
  warcraft: PropTypes.bool,
  retailWarcraft: PropTypes.bool,
  classicWarcraft: PropTypes.bool,
  vanillaWarcraft: PropTypes.bool,
  sodWarcraft: PropTypes.bool,
  ff: PropTypes.bool,
  eso: PropTypes.bool,
  swtor: PropTypes.bool,
};
EnhancedMarkdownIf.compileCondition = (props, context) =>
  shouldRender(props, context.pageViewModels.game);

export default EnhancedMarkdownIf;
