import React, { ReactNode } from 'react';
import Icon, { IconProps } from '../Icon/Icon';
import useGame from '../../contexts/useGame';
import { GameViewModel } from '../../typings/viewModels/gameViewModel';
import useScript from '../../hooks/useScript';
import AsyncFetchWithSpinnerAndErrorBox from '../AsyncFetchWithSpinnerAndErrorBox/AsyncFetchWithSpinnerAndErrorBox';
import type { AbilityTooltipViewModel } from '../../typings/viewModels/abilityTooltipViewModel';
import useIconDefaults from '../../contexts/useIconDefaults';

const createIconSrc = (icon: string, game: GameViewModel | null) => {
  if (icon.startsWith('http') || !game) {
    return icon;
  }

  return `${game.assetServerBaseUrl}/img/${game.lowercaseName}/abilities/${icon}`;
};

export type AbilityIconProps = {
  id: number | string;
  icon?: string;
  children?: ReactNode;
} & Pick<
  IconProps,
  'bordered' | 'rounded' | 'alt' | 'size' | 'tooltip' | 'forceHeight' | 'isRtl'
>;

export default function AbilityIcon({
  id,
  icon,
  size,
  rounded,
  bordered,
  forceHeight = false,
  alt = '',
  children,
  tooltip,
  isRtl,
}: AbilityIconProps): JSX.Element {
  const defaults = useIconDefaults().ability;
  rounded = rounded ?? defaults.rounded;
  bordered = bordered ?? defaults.bordered;
  size = size ?? defaults.size;

  const game = useGame();
  useScript(game?.tooltipLibraryPath);

  const src = icon ? createIconSrc(icon, game) : undefined;

  return (
    <Wrapper id={id} game={game}>
      <Icon
        src={src}
        size={size}
        rounded={rounded}
        bordered={bordered}
        forceHeight={forceHeight}
        alt={alt}
        isRtl={isRtl}
        tooltip={
          tooltip
            ? tooltip
            : !game || game.tooltipLibraryPath
            ? undefined
            : {
                content: (
                  <AsyncFetchWithSpinnerAndErrorBox<AbilityTooltipViewModel>
                    pendingOrRejectedHeight={84}
                    initialRenderDelayWhileLoading={0}
                    url={game.abilityTooltipEndpoint
                      .split('{id}')
                      .join(id.toString())}
                    lazyChildren={(tooltipData) => {
                      if (!tooltipData) {
                        return null;
                      }

                      return (
                        <AbilityTooltip
                          {...tooltipData}
                          iconImageUrl={tooltipData.iconImageUrl ?? icon}
                          game={game}
                        />
                      );
                    }}
                  />
                ),
              }
        }
      >
        {children}
      </Icon>
    </Wrapper>
  );
}

type WrapperProps = {
  id: AbilityIconProps['id'];
  children: ReactNode;
  game: GameViewModel | null;
};

function Wrapper({ id, children, game }: WrapperProps) {
  if (id && game) {
    const href =
      game.lowercaseName === 'ff' && typeof id === 'number' && id > 1000000
        ? `${game.buffExternalSitePath}${id}`
        : `${game.abilityExternalSitePath}${id}`;

    return (
      <a
        className='ability-icon'
        href={href}
        target={'_blank'}
        rel={'nofollow noreferrer'}
      >
        {children}
      </a>
    );
  }

  return <span className='ability-icon'>{children}</span>;
}

type TooltipProps = AbilityTooltipViewModel & {
  game: GameViewModel;
};

function AbilityTooltip({
  description,
  iconImageUrl,
  title,
  game,
}: TooltipProps) {
  return (
    <table className='ability-icon__tooltip'>
      <tbody>
        <tr>
          {iconImageUrl && (
            <td>
              <img
                className='ability-icon__tooltip--icon'
                src={createIconSrc(iconImageUrl, game)}
              />
            </td>
          )}
          <td>
            <div className='estimate'>{title}</div>
            {description && (
              <table className='ability-icon__tooltip--description'>
                <tbody>
                  <tr>
                    <td
                      className='ability-icon__tooltip--description__content'
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  </tr>
                </tbody>
              </table>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
