import React, { ReactNode } from 'react';
import Bar, { BarProps } from '../../Bar/Bar';

type EnhancedMarkdownBarProps = Pick<BarProps, 'percent'> & {
  type?: string;
  label?: string | ReactNode;
};

export default function EnhancedMarkdownBar({
  percent,
  type,
  label,
}: EnhancedMarkdownBarProps): JSX.Element {
  return (
    <Bar
      percent={percent}
      type={type}
      tooltipProps={label ? { content: label } : undefined}
    />
  );
}
