import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

function EnhancedMarkdownDivider({ purple }) {
  return (
    <div
      className={cx('enhanced-markdown-divider', {
        'enhanced-markdown-divider--purple': purple,
      })}
    />
  );
}
EnhancedMarkdownDivider.propTypes = {
  purple: PropTypes.bool,
};

export default EnhancedMarkdownDivider;
