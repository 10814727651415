import React from 'react';
import PropTypes from 'prop-types';

function EnhancedMarkdownCenter({ children }) {
  return <div className={'enhanced-markdown-center'}>{children}</div>;
}
EnhancedMarkdownCenter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EnhancedMarkdownCenter;
