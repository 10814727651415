import React, { useContext, ReactNode, createContext } from 'react';
import { HeadingStyle } from '../components/Heading/Heading';
import { ButtonStyle } from '../components/Button/Button';
import { TableStyle } from '../components/Table/Table';
import { InputTextStyle } from '../components/InputText/InputText';
import { SelectStyle } from '../components/Select/Select';
import { TileStyle } from '../components/Tile/Tile';
import { ToggleSelectStyle } from '../components/ToggleSelect/ToggleSelect';
import { TabbedSelectStyle } from '../components/TabbedSelect/TabbedSelect';
import { BodyStyle } from '../components/Body/Body';
import { IconStyle } from '@resources/js/components/Icon/Icon';

export type ThemeContext = {
  name: 'v1' | 'v2' | 'article' | 'v3';
  bodyStyle: BodyStyle;
  headingStyle: HeadingStyle;
  primaryButtonStyles: ButtonStyle[];
  secondaryButtonStyles: ButtonStyle[];
  warningButtonStyles: ButtonStyle[];
  tableStyle: TableStyle;
  horizontalRuleColor: 'light-gray' | 'lighter-gray';
  inputTextStyle: InputTextStyle;
  selectStyles: SelectStyle[];
  tileStyle: TileStyle;
  toggleSelectStyle: ToggleSelectStyle;
  tabbedSelectStyle: TabbedSelectStyle;
  iconStyle: IconStyle;
};

export const V1Theme: ThemeContext = {
  name: 'v1',
  bodyStyle: 'v1',
  headingStyle: 'v1',
  primaryButtonStyles: ['primary'],
  secondaryButtonStyles: ['menu'],
  warningButtonStyles: ['warning'],
  tableStyle: 'report',
  horizontalRuleColor: 'lighter-gray',
  inputTextStyle: 'light',
  selectStyles: [],
  tileStyle: 'tile',
  toggleSelectStyle: 'menu',
  tabbedSelectStyle: 'default',
  iconStyle: 'v1',
};
export const V2Theme: ThemeContext = {
  ...V1Theme,
  name: 'v2',
  bodyStyle: 'v2',
  headingStyle: 'v2',
  tableStyle: 'dark-gray-striped',
  horizontalRuleColor: 'light-gray',
  selectStyles: ['menu'],
};
export const ArticleTheme: ThemeContext = {
  ...V2Theme,
  name: 'article',
  bodyStyle: 'article',
  headingStyle: 'article',
  tileStyle: 'flat',
  inputTextStyle: 'dark',
  tableStyle: 'v2',
};
export const V3Theme: ThemeContext = {
  ...V2Theme,
  name: 'v3',
  bodyStyle: 'v3',
  headingStyle: 'v3',
  primaryButtonStyles: ['gradient-rounded', 'bold'],
  secondaryButtonStyles: ['menu', 'bold'],
  toggleSelectStyle: 'rounded-square',
  tabbedSelectStyle: 'rounded-square',
  inputTextStyle: 'dark',
  tableStyle: 'v2',
  selectStyles: ['menu', 'bold'],
  iconStyle: 'v2',
};

export const V3CompactTheme: ThemeContext = {
  ...V3Theme,
  bodyStyle: 'v3-compact',
  headingStyle: 'v3-compact',
};

const defaultTheme = V2Theme;

export const ThemeContext = createContext<ThemeContext>(defaultTheme);

const useTheme = (): ThemeContext => useContext(ThemeContext) ?? defaultTheme;

export const ThemeContextProvider = ({
  theme,
  children,
}: {
  theme: ThemeContext;
  children: ReactNode;
}): JSX.Element => {
  global?.document
    ?.querySelector('body')
    ?.classList.add(`theme--${theme.name}`);
  global?.document
    ?.querySelector('html')
    ?.classList.add(`theme--${theme.name}`);

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export default useTheme;
