import React, { ReactNode } from 'react';
import Icon, { IconProps } from '../Icon/Icon';
import useGame from '../../contexts/useGame';
import { GameViewModel } from '../../typings/viewModels/gameViewModel';
import useScript from '../../hooks/useScript';
import useIconDefaults from '../../contexts/useIconDefaults';

export type ItemIconProps = {
  id: number;
  icon?: string;
  extraInfo?: string | null;
  children?: ReactNode;
} & Pick<
  IconProps,
  'bordered' | 'rounded' | 'alt' | 'tooltip' | 'size' | 'forceHeight' | 'isRtl'
>;

function determineIconSrc(
  icon: string,
  game: ReturnType<typeof useGame>
): string {
  if (icon.startsWith('http') || !game) {
    return icon;
  }

  const base = `${game.assetServerBaseUrl}/img/${game.lowercaseName}/abilities/${icon}`;

  if (icon.includes('png') || icon.includes('jpg')) {
    return base;
  }

  return `${base}.${game.encounterIconExtension}`;
}

export default function ItemIcon({
  id,
  icon,
  extraInfo,
  size,
  rounded,
  bordered,
  forceHeight = false,
  alt,
  tooltip,
  children,
  isRtl,
}: ItemIconProps): JSX.Element {
  const defaults = useIconDefaults().item;
  rounded = rounded ?? defaults.rounded;
  bordered = bordered ?? defaults.bordered;
  size = size ?? defaults.size;

  const game = useGame();

  useScript(game?.tooltipLibraryPath);

  return (
    <Wrapper id={id} extraInfo={extraInfo} game={game}>
      <Icon
        src={icon ? determineIconSrc(icon, game) : undefined}
        size={size}
        rounded={rounded}
        bordered={bordered}
        forceHeight={forceHeight}
        alt={alt}
        tooltip={tooltip}
        isRtl={isRtl}
      >
        {children}
      </Icon>
    </Wrapper>
  );
}

type WrapperProps = {
  game: GameViewModel | null;
  children: ReactNode;
} & Pick<ItemIconProps, 'extraInfo' | 'id'>;

function Wrapper({
  id,
  game,
  children,
  extraInfo = '',
}: WrapperProps): JSX.Element {
  if (id && game?.abilityExternalSitePath) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={`${game.itemExternalSitePath}${id}`}
        target={'_blank'}
        rel={extraInfo ? extraInfo : 'nofollow'}
      >
        {children}
      </a>
    );
  }

  return <>{children}</>;
}
