import useGame from '@resources/js/contexts/useGame';
import { iconSizeXXL } from '@resources/js/contexts/useIconDefaults';
import React, { Fragment, ReactNode } from 'react';

import AbilityIcon from '../AbilityIcon/AbilityIcon';
import { normalizeItemType } from '../EnhancedMarkdown/components/EnhancedMarkdownItemIcon';
import { normalizeType } from '../EnhancedMarkdown/components/EnhancedMarkdownStyled';
import ItemIcon, { ItemIconProps } from '../ItemIcon/ItemIcon';

type StatViewModel = {
  class: string;
  name: string;
};

type GemOrEnchantViewModel = {
  id: number;
  icon: string;
  name: string;
  type: number;
};

export type GearIconProps = ItemIconProps & {
  stats?: StatViewModel[];
  enchants?: GemOrEnchantViewModel[];
  gems?: GemOrEnchantViewModel[];
  type: number;
  subLabel?: string;
  side?: 'left' | 'right';
  extraInfo?: string;
  isAbility?: boolean;
};

export default function GearIcon({
  id,
  stats,
  enchants,
  icon,
  gems,
  children,
  type,
  subLabel,
  side = 'left',
  extraInfo,
  isAbility,
}: GearIconProps): JSX.Element {
  const enchant = enchants && enchants.length > 0 ? enchants[0] : null;
  const hasMeta = enchant || gems || stats;

  const Icon = isAbility ? AbilityIcon : ItemIcon;

  return (
    <div className={`gear-icon gear-icon--${side}`}>
      <div className={'gear-icon__icon'}>
        <Icon id={id} icon={icon} size={iconSizeXXL} extraInfo={extraInfo} />
      </div>

      {children &&
        !(
          (Array.isArray(children) || typeof children === 'string') &&
          children.length === 0
        ) && (
          <div
            className={`gear-icon__item ${
              hasMeta ? 'gear-icon__item--has-meta' : ''
            }`}
          >
            <div className={'gear-icon__item-name'}>
              <div className={'gear-icon__item-name-icon'}>
                <ItemIcon id={id} icon={icon} extraInfo={extraInfo} size={28} />
              </div>
              <ItemName
                isAbility={isAbility}
                id={id}
                extraInfo={extraInfo}
                type={type}
              >
                {children}
              </ItemName>
            </div>

            {subLabel && (
              <div className={'gear-icon__item-sublabel'}>{subLabel}</div>
            )}

            {hasMeta ? (
              <div className='gear-icon__item-meta'>
                {enchant && gems ? (
                  <div className='gear-icon__item-meta__multitype'>
                    {gems.map((gem, index) => {
                      return (
                        <ItemIcon id={gem.id} icon={gem.icon} key={index} />
                      );
                    })}

                    <ItemIcon
                      id={enchant.id}
                      icon={enchant.icon}
                      isRtl={side === 'right'}
                    >
                      {gems.length === 1 ? (
                        <span
                          className={normalizeType(
                            normalizeItemType(enchant.type)
                          )}
                        >
                          {enchant.name}
                        </span>
                      ) : undefined}
                    </ItemIcon>
                  </div>
                ) : null}

                {gems && !enchant ? (
                  <div className={'gear-icon__item-meta__gems'}>
                    {gems.map((gem, index) => {
                      return (
                        <ItemIcon id={gem.id} icon={gem.icon} key={index}>
                          {gems.length === 1 ? (
                            <span
                              className={normalizeType(
                                normalizeItemType(gem.type)
                              )}
                            >
                              {gem.name}
                            </span>
                          ) : undefined}
                        </ItemIcon>
                      );
                    })}
                  </div>
                ) : null}

                {enchant && !gems ? (
                  <div className={'gear-icon__item-meta__enchant'}>
                    <ItemIcon id={enchant.id} icon={enchant.icon}>
                      <span
                        className={normalizeType(
                          normalizeItemType(enchant.type)
                        )}
                      >
                        {enchant.name}
                      </span>
                    </ItemIcon>
                  </div>
                ) : null}

                {stats ? (
                  <div className='gear-icon__item-meta__stats'>
                    {stats.map((stat, index) => (
                      <Fragment key={index}>
                        <span className={stat.class}>{stat.name}</span>
                        {index === stats.length - 1 ? null : ' / '}
                      </Fragment>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
    </div>
  );
}

type ItemNameProps = Pick<
  GearIconProps,
  'type' | 'isAbility' | 'id' | 'extraInfo'
> & {
  children: ReactNode | ReactNode[];
};

function ItemName({ type, children, id, extraInfo, isAbility }: ItemNameProps) {
  const game = useGame();

  if (!game?.itemExternalSitePath) {
    return (
      <span className={normalizeType(normalizeItemType(type))}>{children}</span>
    );
  }

  const anchorProps = {
    target: '_blank',
    rel: extraInfo ?? 'noreferrer',
    href: `${
      isAbility ? game.abilityExternalSitePath : game.itemExternalSitePath
    }${id}`,
    className: normalizeType(normalizeItemType(type)),
  };

  if (Array.isArray(children) && children.length > 1) {
    // small hack. given wowhead bis links, this is an array where the first
    // element is the wowhead link and the second is a span including the item name
    // this also prevents nesting <a> within <a>
    const [wowheadBisIndicator, itemName] = children;

    return (
      <span>
        {wowheadBisIndicator}
        <a {...anchorProps}>{itemName}</a>
      </span>
    );
  }

  return <a {...anchorProps}>{children}</a>;
}
