import React from 'react';
import YoutubeEmbed from '../../YoutubeEmbed/YoutubeEmbed';
import ErrorBox from '../../ErrorBox/ErrorBox';

type EnhancedMarkdownVideoProps = {
  url: string;
};

export default function EnhancedMarkdownVideo({
  url,
}: EnhancedMarkdownVideoProps): JSX.Element {
  const parts = url.split('?');
  const searchParams = new URLSearchParams(
    parts.length > 1 ? parts[1] : parts[0]
  );
  const code = searchParams.get('v');

  if (!code) {
    return (
      <ErrorBox>Invalid URL specified (must include &quot;v=&quot;).</ErrorBox>
    );
  }

  return <YoutubeEmbed code={code} />;
}
