import React, {
  MouseEventHandler,
  useRef,
  ReactNode,
  Fragment,
  KeyboardEvent,
} from 'react';
import isInIframe from '../../helpers/isInIframe';
import { ButtonStyle } from '../Button/Button';
import useTheme from '@resources/js/contexts/useTheme';
import classNames from 'classnames';

// these types are a bit of a mess due to Next 14s hard requirement on not nesting <a> within <Link>
// on top of that, there's multiple level of passing props through components where the types clash due to href being optional
// e.g. any LinkButton use in Archon as well as DownloadButton
// below currently works but should probably be revisited and extracted into a next-specific LinkButton and a regular one

export type LinkButtonProps = {
  openInNewTab?: boolean;
  styles?: ButtonStyle[];
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  children: ReactNode;
} & (
  | {
      wrapperInheritsProps: true;
      Wrapper: (props: {
        children: ReactNode;
        href: string;
      }) => JSX.Element | null;
      href: string;
    }
  | {
      href?: string;
      wrapperInheritsProps?: false;
      Wrapper?: (props: { children: ReactNode }) => JSX.Element | null;
    }
);

function LinkButton({
  href,
  openInNewTab = false,
  styles,
  className,
  onClick,
  Wrapper = Fragment,
  children,
  wrapperInheritsProps,
}: LinkButtonProps): JSX.Element {
  const theme = useTheme();
  styles = styles ?? theme.primaryButtonStyles;

  const elementRef = useRef<HTMLAnchorElement | null>(null);
  openInNewTab = openInNewTab && !isInIframe();

  if (!href)
    styles = [...styles.filter((style) => style !== 'disabled'), 'disabled'];

  const props = {
    className: classNames(
      'link-button react-button',
      styles.map((style) => `react-button--style-${style}`),
      className
    ),
    href,
    target: openInNewTab ? '_blank' : undefined,
    rel: openInNewTab ? 'noopener noreferrer' : undefined,
    onClick,
    onKeyPress: (event: KeyboardEvent<HTMLAnchorElement>) => {
      if (event.key === ' ') {
        elementRef.current?.click();
        event.preventDefault();
      }
    },
  };

  if (wrapperInheritsProps && props.href) {
    return (
      <PropsInheritingLinkButtonWrapper
        {...props}
        Wrapper={Wrapper}
        href={props.href}
      >
        {children}
      </PropsInheritingLinkButtonWrapper>
    );
  }

  return <DefaultLinkButton {...props}>{children}</DefaultLinkButton>;
}

type DefaultLinkButtonProps = {
  Wrapper?: (props: { children: ReactNode }) => JSX.Element | null;
} & Pick<
  LinkButtonProps,
  'openInNewTab' | 'styles' | 'className' | 'onClick' | 'children'
>;

function DefaultLinkButton({
  Wrapper = Fragment,
  children,
  ...props
}: DefaultLinkButtonProps) {
  return (
    <Wrapper>
      <a {...props}>{children}</a>
    </Wrapper>
  );
}

type WrappedLinkButtonPropsInheritingProps = {
  Wrapper: (props: { children: ReactNode; href: string }) => JSX.Element | null;
  href: string;
} & Pick<
  LinkButtonProps,
  'openInNewTab' | 'styles' | 'className' | 'onClick' | 'children'
>;

function PropsInheritingLinkButtonWrapper({
  Wrapper,
  href,
  children,
  ...props
}: WrappedLinkButtonPropsInheritingProps) {
  return (
    <Wrapper {...props} href={href}>
      {children}
    </Wrapper>
  );
}

export default LinkButton;
