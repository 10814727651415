import React from 'react';
import PropTypes from 'prop-types';

const EnhancedMarkdownIframe = ({
  src,
  width = '100%',
  height = '400px',
  scrolling = 'no',
}) => {
  return (
    <div className={'enhanced-markdown-iframe'}>
      <iframe
        loading='lazy'
        src={src}
        width={width}
        height={height}
        scrolling={scrolling}
        frameBorder='0'
        style={{
          overflow: 'auto',
        }}
      />
    </div>
  );
};
EnhancedMarkdownIframe.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  scrolling: PropTypes.string,
};

export default EnhancedMarkdownIframe;
