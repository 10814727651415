import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '../../contexts/useTheme';

function HorizontalRule({ color }) {
  const theme = useTheme();
  color = color ?? theme.horizontalRuleColor;

  return <hr className={`horizontal-rule horizontal-rule--color-${color}`} />;
}
HorizontalRule.propTypes = {
  color: PropTypes.oneOf(['light-gray', 'lighter-gray']),
};

export default HorizontalRule;
