export type School =
  | 'Physical'
  | 'Holy'
  | 'Fire'
  | 'Nature'
  | 'Frost'
  | 'Shadow'
  | 'Shadowflame'
  | 'Arcane'
  | 'Chaos'
  | 'Earth'
  | 'Air'
  | 'Limit Break'
  | number;

export const abilitySchoolMap: Record<
  number,
  NonNullable<Exclude<School, number>>
> = {
  1: 'Physical',
  2: 'Holy',
  4: 'Fire',
  8: 'Nature',
  16: 'Frost',
  32: 'Shadow',
  36: 'Shadowflame',
  64: 'Arcane',
  124: 'Chaos',
  125: 'Chaos',
  126: 'Chaos',
  127: 'Chaos',
  128: 'Earth',
  256: 'Limit Break',
  512: 'Air',
  1024: 'Limit Break',
};

export const normalizeAbilityType = (type?: number | string): string => {
  if (!type) {
    return 'Physical';
  }

  if (typeof type === 'number') {
    // already is a number from direct props
    return abilitySchoolMap[type] ?? 'Physical';
  }

  // might still be a number via markdown props; will be stringified
  const asNumber = Number.parseInt(type);

  if (!Number.isNaN(asNumber)) {
    return abilitySchoolMap[asNumber] ?? 'Physical';
  }

  return type;
};
