import React, { HTMLAttributes, ReactNode, useRef, useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  autoPlacement,
  arrow,
  safePolygon,
} from '@floating-ui/react-dom-interactions';
import { OffsetOptions } from '@floating-ui/core';

export type TooltipProps = {
  children: ReactNode;
  content: ReactNode;
  defaultOpen?: boolean;
  tooltipClassName?: string;
  /**
   * Allow hovering the tooltip content without closing the tooltip.
   */
  allowHover?: boolean;
  placementOptions?: Parameters<typeof autoPlacement>[0];
  zIndex?: number;
  offsetOptions?: OffsetOptions;
} & (
  | {
      interactiveTrigger?: boolean;
      triggerProps?: HTMLAttributes<HTMLButtonElement>;
    }
  | {
      interactiveTrigger?: never;
      triggerProps?: HTMLAttributes<HTMLSpanElement>;
    }
);

export default function Tooltip({
  children,
  content,
  defaultOpen = false,
  tooltipClassName = '',
  triggerProps,
  placementOptions,
  interactiveTrigger,
  allowHover,
  zIndex,
  offsetOptions = 5,
}: TooltipProps): JSX.Element {
  const [open, setOpen] = useState(defaultOpen);
  const arrowRef = useRef<HTMLSpanElement | null>(null);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    middlewareData,
    placement,
  } = useFloating<HTMLButtonElement>({
    open,
    placement: 'bottom',
    onOpenChange: setOpen,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetOptions),
      autoPlacement(placementOptions),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, {
    move: false,
    handleClose: allowHover ? safePolygon() : undefined,
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: 'tooltip' });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const { x: arrowX, y: arrowY } = middlewareData.arrow ?? {};
  const [side] = placement.split('-');

  const arrowSide = arrowSideMap[side];

  const TriggerElement = interactiveTrigger ? 'button' : 'span';

  return (
    <>
      <TriggerElement
        {...getReferenceProps({
          type: interactiveTrigger ? 'button' : undefined,
          ...triggerProps,
          className: ['tooltip--trigger', triggerProps?.className]
            .filter(Boolean)
            .join(' '),
          ref: reference,
        })}
      >
        {children}
      </TriggerElement>
      <FloatingPortal id='tooltip-portal'>
        {open && (
          <>
            <div
              className={`tooltip--body ${tooltipClassName}`}
              ref={floating}
              style={{
                // Positioning styles
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex,
              }}
              {...getFloatingProps()}
            >
              {content}
              <span
                aria-hidden
                className='tooltip--arrow'
                ref={arrowRef}
                data-side={arrowSide}
                style={{
                  left: arrowX ? `${arrowX}px` : '',
                  top: arrowY ? `${arrowY}px` : '',
                  right: '',
                  bottom: '',
                  [arrowSide]: '-0.5rem', // half of its width
                }}
              />
            </div>
          </>
        )}
      </FloatingPortal>
    </>
  );
}

const arrowSideMap: Record<string, string> = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};
