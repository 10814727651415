import React, {
  forwardRef,
  ForwardedRef,
  HTMLAttributes,
  ReactNode,
} from 'react';

type UnstyledLinkProps = {
  className?: string;
  href: string;
  children: ReactNode;
  openInNewTab?: boolean;
  disableDefaultLinkUnstyling?: boolean;
  setRel?: boolean;
} & HTMLAttributes<HTMLAnchorElement>;

const UnstyledLink = forwardRef(function UnstyledLink(
  {
    className,
    href,
    openInNewTab,
    children,
    disableDefaultLinkUnstyling,
    setRel = true,
    ...props
  }: UnstyledLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      ref={ref}
      className={`${disableDefaultLinkUnstyling ? '' : 'unstyled-link'} ${
        className ?? ''
      }`}
      {...props}
      href={href}
      target={openInNewTab ? '_blank' : undefined}
      rel={setRel && openInNewTab ? 'noopener noreferrer' : undefined}
    >
      {children}
    </a>
  );
});

export default UnstyledLink;
