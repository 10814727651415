import React from 'react';
import Icon, { IconProps } from '../Icon/Icon';
import useGame from '../../contexts/useGame';
import useIconDefaults from '../../contexts/useIconDefaults';

export type EncounterIconProps = {
  children?: string;
  id: number;
} & Pick<
  IconProps,
  | 'bordered'
  | 'rounded'
  | 'alt'
  | 'tooltip'
  | 'size'
  | 'forceHeight'
  | 'className'
>;

export default function EncounterIcon({
  id,
  size,
  rounded,
  bordered,
  forceHeight = false,
  alt = '',
  tooltip,
  children,
  className,
}: EncounterIconProps): JSX.Element {
  const defaults = useIconDefaults().encounter;
  rounded = rounded ?? defaults.rounded;
  bordered = bordered ?? defaults.bordered;
  size = size ?? defaults.size;

  const game = useGame();

  return (
    <span className='encounter-icon'>
      {game && (
        <Icon
          src={`${game.assetServerBaseUrl}/img/${
            game.lowercaseName
          }/bosses/${id}-icon.${game.encounterIconExtension ?? 'jpg'}`}
          size={size}
          rounded={rounded}
          bordered={bordered}
          forceHeight={forceHeight}
          alt={alt}
          tooltip={tooltip}
          className={className}
        >
          {children && (
            <span className='do-not-change-color-on-hover Boss'>
              {children}
            </span>
          )}
        </Icon>
      )}
    </span>
  );
}
