import React from 'react';
import useGame from '@resources/js/contexts/useGame';

function WowheadLogoIcon(): JSX.Element | null {
  const game = useGame();

  if (!game) {
    return null;
  }

  const src = `${game.assetServerBaseUrl}/img/wowhead-logo-thumbnail.png`;

  return (
    <img
      className={'wowhead-logo-icon'}
      src={src}
      alt={''}
      width={20}
      height={20}
      loading={'lazy'}
    />
  );
}

export default WowheadLogoIcon;
