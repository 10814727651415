import React from 'react';
import PropTypes from 'prop-types';

function YoutubeEmbed({ code, src }) {
  if (!code && !src) return null;

  const embedUrl = src ?? 'https://www.youtube.com/embed/' + code;

  return (
    <div className={'youtube-embed'}>
      <iframe
        loading='lazy'
        src={embedUrl}
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </div>
  );
}

YoutubeEmbed.propTypes = {
  code: PropTypes.string,
  src: PropTypes.string,
};

export default YoutubeEmbed;
