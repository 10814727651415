import React, { useContext, ReactNode, createContext } from 'react';

type IconDefaults = Record<
  'icon' | 'actor' | 'encounter' | 'item' | 'ability',
  Partial<DefaultableProps>
>;

type DefaultableProps = {
  size: number;
  rounded: boolean;
  bordered: boolean;
};

const defaults = {
  icon: {
    size: 20,
    rounded: true,
    bordered: false,
  },
  actor: {
    size: 20,
    rounded: true,
    bordered: false,
  },
  ability: {
    size: 20,
    rounded: true,
    bordered: false,
  },
  encounter: {
    size: 20,
    rounded: true,
    bordered: false,
  },
  item: {
    size: 20,
    rounded: false,
    bordered: true,
  },
};

export const IconDefaultsContext = createContext<IconDefaults>(defaults);

const useIconDefaults = (): IconDefaults => useContext(IconDefaultsContext);

export const IconDefaultsContextProvider = ({
  overrides,
  children,
}: {
  overrides: Partial<IconDefaults>;
  children: ReactNode;
}): JSX.Element => {
  const value = {
    ...defaults,
    ...(overrides.icon
      ? { icon: { ...defaults.icon, ...overrides.icon } }
      : {}),
    ...(overrides.actor
      ? { actor: { ...defaults.actor, ...overrides.actor } }
      : {}),
    ...(overrides.ability
      ? { ability: { ...defaults.ability, ...overrides.ability } }
      : {}),
    ...(overrides.encounter
      ? { encounter: { ...defaults.encounter, ...overrides.encounter } }
      : {}),
    ...(overrides.item
      ? { item: { ...defaults.item, ...overrides.item } }
      : {}),
  };

  return (
    <IconDefaultsContext.Provider value={value}>
      {children}
    </IconDefaultsContext.Provider>
  );
};

export const iconSizeLarge = 32;
export const iconSizeExtraLarge = 40;
export const iconSizeXXL = 50;

export default useIconDefaults;
