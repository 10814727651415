import React from 'react';
import PropTypes from 'prop-types';

function ScreenReaderOnly({ children, asSpan }) {
  if (asSpan) {
    return <span className={'screen-reader-only'}>{children}</span>;
  }

  return <div className={'screen-reader-only'}>{children}</div>;
}
ScreenReaderOnly.propTypes = {
  children: PropTypes.node.isRequired,
  asSpan: PropTypes.bool,
};

export default ScreenReaderOnly;
