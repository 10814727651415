import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@sentry/react';
import Tile from '../Tile/Tile';
import Markdown from '../Markdown/Markdown';

const TileErrorBoundary = ({ children, ...props }) => {
  return (
    <ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('location', 'TileErrorBoundary');
        scope.setExtra('isReactError', true);
      }}
      fallback={
        <Tile {...props}>
          <Markdown>{trans('global.something_went_wrong_tile')}</Markdown>
        </Tile>
      }
    >
      {children}
    </ErrorBoundary>
  );
};

TileErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default TileErrorBoundary;
