import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import compileEnhancedMarkdown from './helpers/compileEnhancedMarkdown';
import Body from '../Body/Body';

function EnhancedMarkdown({
  markdown,
  overrides,
  wrapped = true,
  onlyUsePassedOverrides = false,
}) {
  const output = useMemo(
    () =>
      compileEnhancedMarkdown(
        markdown,
        overrides,
        undefined,
        onlyUsePassedOverrides
      ),
    [markdown, onlyUsePassedOverrides, overrides]
  );

  if (!wrapped) return output;

  return <Body className={'enhanced-markdown'}>{output}</Body>;
}
EnhancedMarkdown.propTypes = {
  markdown: PropTypes.string.isRequired,
  overrides: PropTypes.object,
  wrapped: PropTypes.bool,
  onlyUsePassedOverrides: PropTypes.bool,
};

export default EnhancedMarkdown;
