import React, { Fragment, ReactNode } from 'react';
import UnstyledLink from '../UnstyledLink/UnstyledLink';

type MaybeLinkProps = {
  href?: string;
  children: ReactNode;
  className?: string;
  openInNewTab?: boolean;
  fallbackButton?: {
    onClick?: () => void;
    isDisabled?: boolean;
  };
  disableDefaultLinkUnstyling?: boolean;
  Wrapper?: ({ children }: { children: ReactNode }) => JSX.Element | null;
  setRel?: boolean;
};

function MaybeLink({
  href,
  children,
  className,
  openInNewTab,
  fallbackButton,
  disableDefaultLinkUnstyling,
  Wrapper = Fragment,
  setRel = true,
}: MaybeLinkProps): JSX.Element | null {
  return (
    <Wrapper>
      {href ? (
        <UnstyledLink
          href={href}
          className={className}
          openInNewTab={openInNewTab}
          disableDefaultLinkUnstyling={disableDefaultLinkUnstyling}
          setRel={setRel}
        >
          {children}
        </UnstyledLink>
      ) : fallbackButton ? (
        <button
          className={className}
          onClick={fallbackButton.onClick}
          disabled={fallbackButton.isDisabled}
        >
          {children}
        </button>
      ) : (
        <>{children}</>
      )}
    </Wrapper>
  );
}

export default MaybeLink;
