import React from 'react';
import useGame from '../../../contexts/useGame';
import PropTypes from 'prop-types';

function EnhancedMarkdownSupportEmailLink({ children }) {
  const game = useGame();
  const email = `support@${game.lowercaseName}logs.com`;

  return <a href={`mailto:${email}`}>{children ?? email}</a>;
}
EnhancedMarkdownSupportEmailLink.propTypes = {
  children: PropTypes.node,
};

export default EnhancedMarkdownSupportEmailLink;
