function collapseComponentsInsideParagraphsIfNecessary(output) {
  if (!Array.isArray(output.props.children)) return;

  const newChildren = [];
  for (let i = 0; i < output.props.children.length; i++) {
    let child = output.props.children[i];

    if (child.props?.children)
      collapseComponentsInsideParagraphsIfNecessary(child);

    if (i === output.props.children.length - 1) {
      newChildren.push(child);
      break;
    }

    const childIsComponent = typeof child.type === 'function';
    if (!childIsComponent) {
      newChildren.push(child);
      continue;
    }

    const nextChild = output.props.children[i + 1];
    const nextChildIsParagraph =
      nextChild.type === 'p' ||
      nextChild.type?.name === 'p' ||
      // for example, ParagraphThatIsHiddenOnCompact from buildsEnhancedMarkdownOverrides
      nextChild.type?.name?.startsWith('Paragraph');
    if (!nextChildIsParagraph) {
      newChildren.push(child);
      continue;
    }

    const paragraphBeginsWithSpace =
      nextChild.props.children?.[0]?.charAt?.(0) === ' ';
    if (!paragraphBeginsWithSpace) {
      newChildren.push(child);
      continue;
    }

    if (child.key) {
      // Ensure we don't use an existing key
      child = {
        ...child,
        key: nextChild.props.children.length.toString(),
      };
    }
    nextChild.props.children.unshift(child);
    newChildren.push(nextChild);
    i++;
  }
  output.props.children.splice(0, output.props.children.length);
  newChildren.forEach((child) => output.props.children.push(child));
}

export default collapseComponentsInsideParagraphsIfNecessary;
