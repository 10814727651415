import React from 'react';
import PropTypes from 'prop-types';
import ErrorBox from '../../ErrorBox/ErrorBox';
import { ASSET_SERVER_BASE_URL } from '../../../constants/assetServer';

function EnhancedMarkdownImage({
  src,
  alt,
  width,
  height,
  includeMargin = true,
}) {
  if (!src?.startsWith?.(ASSET_SERVER_BASE_URL))
    return (
      <ErrorBox>
        <p>
          Every <code>&lt;Image&gt;</code> must specify a <code>src</code>{' '}
          property that starts with <code>{ASSET_SERVER_BASE_URL}</code>.
        </p>
      </ErrorBox>
    );

  if (!(alt?.length ?? 0) > 0)
    return (
      <ErrorBox>
        <p>
          Every <code>&lt;Image&gt;</code> must specify an <code>alt</code>{' '}
          property that describes the image.
        </p>
      </ErrorBox>
    );

  return (
    <div
      className={`enhanced-markdown-image ${
        includeMargin ? 'enhanced-markdown-image--include-margin' : ''
      }`}
    >
      <img src={src} alt={alt} width={width} height={height} />
    </div>
  );
}
EnhancedMarkdownImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  includeMargin: PropTypes.bool,
};

export default EnhancedMarkdownImage;
