import React, { CSSProperties, ReactNode } from 'react';
import useTheme from '../../contexts/useTheme';

type HeadingProps = {
  level: HeadingLevel;
  style?: HeadingStyle;
  styleLevel?: HeadingLevel;
  cssStyle?: CSSProperties;
  className?: string;
  id?: string;
  children?: ReactNode;
  as?: keyof React.ReactHTML;
};

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
export type HeadingStyle =
  | 'v1'
  | 'v2'
  | 'article'
  | 'v3'
  | 'v3-with-header-margin'
  | 'v3-compact';

export default function Heading({
  level,
  style,
  styleLevel,
  cssStyle,
  className,
  children,
  as: AsElement,
  ...attributes
}: HeadingProps): JSX.Element {
  const Component = AsElement ? AsElement : levelToTag[level];
  const theme = useTheme();
  style = style ?? theme.headingStyle;
  className = `${className ? className + ' ' : ''}heading heading--level-${
    styleLevel ?? level
  } ${styleToClassName(style)}`;

  return (
    <Component className={className} style={cssStyle} {...attributes}>
      {children}
    </Component>
  );
}

type HeadingTagProps = {
  className: string;
  style?: CSSProperties;
  children: ReactNode;
};

const levelToTag: Record<
  HeadingLevel,
  (props: HeadingTagProps) => JSX.Element
> = {
  1: (props) => <h1 {...props} />,
  2: (props) => <h2 {...props} />,
  3: (props) => <h3 {...props} />,
  4: (props) => <h4 {...props} />,
  5: (props) => <h5 {...props} />,
  6: (props) => <h6 {...props} />,
};

const styleToClassName = (style: HeadingStyle): string => {
  if (style === 'v3-with-header-margin') {
    return 'heading--style-v3 heading--with-margin';
  }

  return `heading--style-${style}`;
};
