import useGame from '@resources/js/contexts/useGame';
import useScript from '../../hooks/useScript';
import React from 'react';

export type ItemSetProps = {
  id: number;
  children: string;
};

export default function ItemSet({ id, children }: ItemSetProps): JSX.Element {
  const game = useGame();
  useScript(game?.tooltipLibraryPath);

  if (!game?.itemSetExternalSitePath) {
    return <>{children}</>;
  }

  const href = `${game.itemSetExternalSitePath}${id}`;

  return (
    <a
      className='school-1'
      href={href}
      target={'_blank'}
      rel={'nofollow noreferrer'}
    >
      {children}
    </a>
  );
}
