import React from 'react';

function EnhancedMarkdownCodeTitle({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className={'enhanced-markdown-code-title'}>{children}</div>;
}

export default EnhancedMarkdownCodeTitle;
