import EnhancedMarkdownSiteTitle from '../components/EnhancedMarkdownSiteTitle';
import EnhancedMarkdownIf from '../components/EnhancedMarkdownIf';
import getDisallowedHtmlTags from './getDisallowedHtmlTags';
import React from 'react';
import { compiler } from 'markdown-to-jsx';
import collapseComponentsInsideParagraphsIfNecessary from './collapseComponentsInsideParagraphsIfNecessary';
import EnhancedMarkdownImage from '../components/EnhancedMarkdownImage';
import EnhancedMarkdownZmdiIcon from '../components/EnhancedMarkdownZmdiIcon';
import EnhancedMarkdownGameName from '../components/EnhancedMarkdownGameName';
import EnhancedMarkdownTile from '../components/EnhancedMarkdownTile';
import EnhancedMarkdownSupportEmailLink from '../components/EnhancedMarkdownSupportEmailLink';
import EnhancedMarkdownStyled, {
  buildEnhancedMarkdownStyledShorthands,
} from '../components/EnhancedMarkdownStyled';
import EnhancedMarkdownActorIcon from '../components/EnhancedMarkdownActorIcon';
import HorizontalRule from '../../HorizontalRule/HorizontalRule';
import EnhancedMarkdownArrow, {
  buildEnhancedMarkdownArrowShortHands,
} from '../components/EnhancedMarkdownArrow';
import EnhancedMarkdownCenter from '../components/EnhancedMarkdownCenter';
import EnhancedMarkdownCollapse from '../components/EnhancedMarkdownCollapse';
import EnhancedMarkdownDiscordInviteLink from '../components/EnhancedMarkdownDiscordInviteLink';
import removeConditionalComponents from './removeConditionalComponents';
import EnhancedMarkdownSnippetPlaceholder from '../components/EnhancedMarkdownSnippetPlaceholder';
import EnhancedMarkdownIframe from '../components/EnhancedMarkdownIframe';
import EnhancedMarkdownDivider from '../components/EnhancedMarkdownDivider';
import EnhancedMarkdownTwitterLink from '../components/EnhancedMarkdownTwitterLink';
import EnhancedMarkdownCode from '../components/EnhancedMarkdownCode';
import EnhancedMarkdownCodeTitle from '../components/EnhancedMarkdownCodeTitle';
import EnhancedMarkdownAbilityIcon from '../components/EnhancedMarkdownAbilityIcon';
import EnhancedMarkdownItemIcon from '../components/EnhancedMarkdownItemIcon';
import EnhancedMarkdownEncounterIcon from '../components/EnhancedMarkdownEncounterIcon';
import EnhancedMarkdownBar from '../components/EnhancedMarkdownBar';
import EnhancedMarkdownIcon from '../components/EnhancedMarkdownIcon';
import EnhancedMarkdownVideo from '../components/EnhancedMarkdownVideo';
import Heading from '../../Heading/Heading';
import EnhancedMarkdownItemSet from '../components/EnhancedMarkdownItemSet';
import EnhancedMarkdownGearIcon from '../components/EnhancedMarkdownGearIcon';
import EnhancedMarkdownTooltip from '@resources/js/components/EnhancedMarkdown/components/EnhancedMarkdownTooltip';
import EnhancedMarkdownBadgeLabel from '@resources/js/components/EnhancedMarkdown/components/EnhancedMarkdownBadgeLabel';
import WowheadLogoIcon from '@resources/js/components/WowheadLogoIcon/WowheadLogoIcon';

function compileEnhancedMarkdown(
  markdown,
  overrides = {},
  context,
  onlyUsePassedOverrides = false
) {
  overrides = onlyUsePassedOverrides
    ? overrides
    : {
        ...defaultEnhancedMarkdownOverrides,
        ...overrides,
      };
  const overrideKeys = Object.keys(overrides).map((x) => x.toLowerCase());
  const options = {
    overrides: { ...overrides },
    namedCodesToUnicode: {
      zwj: '\u200d',
    },
  };
  getDisallowedHtmlTags().forEach((tag) => {
    options.overrides[tag] = ({ children }) => children;

    if (!overrideKeys.includes(tag.toLowerCase())) {
      markdown = markdown
        .replace(new RegExp(`<\\s*${tag} `, 'ig'), `&lt;${tag} `)
        .replace(new RegExp(`<\\/?\\s*${tag}\\s*>`, 'ig'), `&lt;${tag}&gt;`);
    }
  });
  options.wrapper = React.Fragment;

  const output = compiler(markdown, options);
  removeConditionalComponents(output, context);
  collapseComponentsInsideParagraphsIfNecessary(output);

  return output;
}

export const defaultEnhancedMarkdownOverrides = {
  h1: (props) => <Heading level={2} {...props} />,
  h2: (props) => <Heading level={3} {...props} />,
  h3: (props) => <Heading level={4} {...props} />,
  h4: (props) => <Heading level={5} {...props} />,
  h5: (props) => <Heading level={6} {...props} />,
  h6: (props) => <Heading level={6} {...props} />,
  hr: HorizontalRule,
  pre: EnhancedMarkdownCode,
  CodeTitle: EnhancedMarkdownCodeTitle,
  Divider: EnhancedMarkdownDivider,
  Center: EnhancedMarkdownCenter,
  SiteTitle: EnhancedMarkdownSiteTitle,
  GameName: EnhancedMarkdownGameName,
  SupportEmailLink: EnhancedMarkdownSupportEmailLink,
  DiscordInviteLink: EnhancedMarkdownDiscordInviteLink,
  TwitterLink: EnhancedMarkdownTwitterLink,
  Icon: EnhancedMarkdownZmdiIcon,
  If: EnhancedMarkdownIf,
  Iframe: EnhancedMarkdownIframe,
  Image: EnhancedMarkdownImage,
  img: EnhancedMarkdownImage,
  DescriptionList: (props) => <dl {...props} />,
  Term: (props) => <dt {...props} />,
  Description: (props) => <dd {...props} />,
  Tile: EnhancedMarkdownTile,
  Styled: EnhancedMarkdownStyled,
  ActorIcon: EnhancedMarkdownActorIcon,
  Arrow: EnhancedMarkdownArrow,
  Collapse: EnhancedMarkdownCollapse,
  Snippet: EnhancedMarkdownSnippetPlaceholder,
  AbilityIcon: EnhancedMarkdownAbilityIcon,
  ItemIcon: EnhancedMarkdownItemIcon,
  EncounterIcon: EnhancedMarkdownEncounterIcon,
  Bar: EnhancedMarkdownBar,
  ImageIcon: EnhancedMarkdownIcon,
  Video: EnhancedMarkdownVideo,
  ItemSet: EnhancedMarkdownItemSet,
  GearIcon: EnhancedMarkdownGearIcon,
  Tooltip: EnhancedMarkdownTooltip,
  BadgeLabel: EnhancedMarkdownBadgeLabel,
  WowheadLogoIcon: WowheadLogoIcon,
  ...buildEnhancedMarkdownStyledShorthands(),
  ...buildEnhancedMarkdownArrowShortHands(),
};

export default compileEnhancedMarkdown;
