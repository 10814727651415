import React, { HTMLAttributes, ReactNode } from 'react';

export type HorizontalContentProps = {
  children: ReactNode;
  className?: string;
  gap?: 'tiny' | 'smaller' | 'small' | 'content-padding' | 'large' | 'xlarge';
  style?: HTMLAttributes<HTMLDivElement>['style'];
};

export default function HorizontalContent({
  children,
  className = '',
  gap = 'smaller',
  style = {},
}: HorizontalContentProps): JSX.Element {
  return (
    <div
      className={`horizontal-content horizontal-content--${gap} ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}
