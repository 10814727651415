import React, { ReactNode } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from '../Button/Button';

type ErrorBoxProps = {
  children: ReactNode;
  className?: string;
  showCopyButton?: boolean;
};

// e.g. login errors shouldn't be copyable
const uncopyableErrors = ['incorrect_password'];

export default function ErrorBox({
  children,
  className,
  showCopyButton = true,
}: ErrorBoxProps): JSX.Element {
  return (
    <div className={`error-box ${className ? className : ''}`}>
      {typeof children === 'string' &&
      !uncopyableErrors.includes(children) &&
      showCopyButton ? (
        <>
          {children}
          <CopyToClipboard text={children}>
            <Button className={'error-box__copy-button'} styles={['link']}>
              <span>{trans('global.copy')}</span>
            </Button>
          </CopyToClipboard>
        </>
      ) : (
        children
      )}
    </div>
  );
}
