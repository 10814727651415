function removeConditionalComponents(output, context) {
  if (!context || !Array.isArray(output.props.children)) {
    return;
  }

  removeConditionalComponentsFromChildren(
    output.props.children,
    context.pageViewModels?.game
  );
}

export function removeConditionalComponentsFromChildren(children, game) {
  if (!children) {
    return;
  }

  const newChildren = [];

  const context = {
    pageViewModels: {
      game,
    },
  };

  const childrenAsArray = Array.isArray(children) ? children : [children];

  childrenAsArray.forEach((child) => {
    const compileCondition = child?.type?.compileCondition;
    if (
      !compileCondition ||
      typeof compileCondition !== 'function' ||
      compileCondition(child.props, context)
    ) {
      newChildren.push(child);
    }

    if (child.props?.children) removeConditionalComponents(child, context);
  });
  childrenAsArray.splice(0, childrenAsArray.length);
  newChildren.forEach((child) => childrenAsArray.push(child));
}

export default removeConditionalComponents;
