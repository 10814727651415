import PropTypes from 'prop-types';

function EnhancedMarkdownSnippetPlaceholder({ slug }) {
  if (!slug || slug === true) return null;

  return `~{snippet:${slug}}~`;
}
EnhancedMarkdownSnippetPlaceholder.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default EnhancedMarkdownSnippetPlaceholder;
