import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import EnhancedMarkdown from '../EnhancedMarkdown';

export function getAllEnhancedMarkdownStyleTypes() {
  return [
    // Generic
    'Pet',
    'NPC',
    'Boss',
    'Primary',

    // Warcraft
    'DeathKnight',
    'DemonHunter',
    'Druid',
    'Evoker',
    'Hunter',
    'Mage',
    'Monk',
    'Paladin',
    'Priest',
    'Rogue',
    'Shaman',
    'Warlock',
    'Warrior',
    'Legendary',
    'Astounding',
    'Magnificent',
    'Epic',
    'Rare',
    'Uncommon',
    'Common',
    'CommonWhite',
    'Exploit',
    'Banned',
    'Kill',
    'Wipe',
    'Estimate',
    'Physical',
    'Holy',
    'Fire',
    'Nature',
    'Frost',
    'Shadow',
    'Chromatic',
    'Arcane',
    'Chaos',
    'Alliance',
    'Horde',

    // FF
    'Arcanist',
    'Archer',
    'Astrologian',
    'Bard',
    'BlackMage',
    'BlueMage',
    'Conjurer',
    'Dancer',
    'DarkKnight',
    'Dragoon',
    'Gladiator',
    'Gunbreaker',
    'Lancer',
    'LimitBreak',
    'Machinist',
    'Marauder',
    'Ninja',
    'Pictomancer',
    'Pugilist',
    'Reaper',
    'RedMage',
    'Sage',
    'Samurai',
    'Scholar',
    'Summoner',
    'Thaumaturge',
    'Viper',
    'WhiteMage',
    'Earth',
    'Air',
    'RDPS',
    'ADPS',
    'CDPS',

    // ESO
    'DragonKnight',
    'Templar',
    'Sorcerer',
    'Warden',
    'Necromancer',
    'Nightblade',
    'Werewolf',

    // SWTOR
    'SithSorcerer',
    'JediSage',
    'SithAssassin',
    'JediShadow',
    'SithJuggernaut',
    'JediGuardian',
    'SithMarauder',
    'JediSentinel',
    'Operative',
    'Scoundrel',
    'Sniper',
    'Gunslinger',
    'Powertech',
    'Vanguard',
    'Mercenary',
    'Commando',
  ];
}

// eslint-disable-next-line react/prop-types
function buildShortHand(type) {
  // eslint-disable-next-line react/prop-types
  return ({ children }) => (
    <EnhancedMarkdownStyled type={type}>
      {children ?? startCase(type)}
    </EnhancedMarkdownStyled>
  );
}

export function buildEnhancedMarkdownStyledShorthands() {
  const shortHands = {};

  getAllEnhancedMarkdownStyleTypes().forEach((styleType) => {
    shortHands[styleType] = buildShortHand(styleType);
  });

  shortHands.AllStyles = EnhancedMarkdownAllStyles;

  return shortHands;
}

export function normalizeType(type) {
  switch (type) {
    case 'Artifact':
      return 'artifact';
    case 'Legendary':
      return 'legendary';
    case 'Astounding':
      return 'astounding';
    case 'Magnificent':
      return 'magnificent';
    case 'Epic':
      return 'epic';
    case 'Rare':
      return 'rare';
    case 'Uncommon':
      return 'uncommon';
    case 'Common':
      return 'common';
    case 'CommonWhite':
      return 'common-white';
    case 'Exploit':
      return 'exploit-fg';
    case 'Banned':
      return 'banned-fg';
    case 'Kill':
      return 'kill';
    case 'Wipe':
      return 'wipe';
    case 'Estimate':
      return 'estimate';
    case 'Physical':
      return 'school-1';
    case 'Holy':
      return 'school-2';
    case 'Fire':
      return 'school-4';
    case 'Nature':
      return 'school-8';
    case 'Frost':
      return 'school-16';
    case 'Shadow':
      return 'school-32';
    case 'Shadowflame':
      return 'school-36';
    case 'Chromatic':
      return 'school-62';
    case 'Arcane':
      return 'school-64';
    case 'Chaos':
      return 'school-127';
    case 'Earth':
      return 'school-128';
    case 'Air':
      return 'school-512';
    case 'Limit Break':
      return 'school-256';
    case 'Primary':
      return 'primary';
    case 'RDPS':
      return 'rdps';
    case 'ADPS':
      return 'adps';
    case 'CDPS':
      return 'cdps';
    case 'Alliance':
      return 'faction-0';
    case 'Horde':
      return 'faction-1';
    case 'Intellect':
      return 'stat-intellect';
    case 'Strength':
      return 'stat-strength';
    case 'Agility':
      return 'stat-agility';
    case 'Versatility':
      return 'stat-versatility';
    case 'Crit':
      return 'stat-crit';
    case 'Mastery':
      return 'stat-mastery';
    case 'Haste':
      return 'stat-haste';
    default:
      return type;
  }
}

function EnhancedMarkdownStyled({ type, children }) {
  const normalizedType = normalizeType(type);

  return (
    <span className={`do-not-change-color-on-hover ` + normalizedType}>
      {children}
    </span>
  );
}
EnhancedMarkdownStyled.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export function EnhancedMarkdownAllStyles() {
  return (
    <EnhancedMarkdown
      markdown={getAllEnhancedMarkdownStyleTypes()
        .map((x) => `<${x}>`)
        .join(', ')}
    />
  );
}

export default EnhancedMarkdownStyled;
