import { useEffect, useState } from 'react';

/**
 * This hook lets you asynchronously load a CSS style sheet.
 *
 * @example
 * const status = useStyleSheet(
 *   "https://example.tld/styles.css"
 * );
 * { status === ready && <ComponentToRenderOnceStyleSheetIsLoaded /> }
 *
 * @param href
 * @returns {string}
 */
function useStyleSheet(href) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(href ? 'loading' : 'idle');

  useEffect(
    () => {
      // Allow falsy href value if waiting on other data needed for
      // constructing the style sheet URL passed to this hook.
      if (!href) {
        setStatus('idle');
        return;
      }

      // Fetch existing style sheet element by href
      // It may have been added by another instance of this hook
      let element = document.querySelector(`link[href="${href}"]`);

      if (!element) {
        // Create style sheet
        element = document.createElement('link');
        element.href = href;
        element.async = true;
        element.rel = 'stylesheet';
        element.setAttribute('data-status', 'loading');
        // Add style sheet to document body
        document.body.appendChild(element);

        // Store status in attribute on style sheet
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          element.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error'
          );
        };

        element.addEventListener('load', setAttributeFromEvent);
        element.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing style sheet status from attribute and set to state.
        setStatus(element.getAttribute('data-status'));
      }

      // Style sheet event handler to update status in state
      // Note: Even if the style sheet already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };

      // Add event listeners
      element.addEventListener('load', setStateFromEvent);
      element.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (element) {
          element.removeEventListener('load', setStateFromEvent);
          element.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [href] // Only re-run effect if style sheet href changes
  );
  return status;
}

export default useStyleSheet;
