import React from 'react';
import Icon, { IconProps } from '../Icon/Icon';
import useGame from '../../contexts/useGame';
import useIconDefaults from '../../contexts/useIconDefaults';

export type ActorIconProps = {
  useSecondarySprites?: boolean;
  type: string;
} & Pick<
  IconProps,
  | 'bordered'
  | 'rounded'
  | 'size'
  | 'tooltip'
  | 'alt'
  | 'children'
  | 'forceHeight'
  | 'className'
>;

export default function ActorIcon({
  type,
  size,
  rounded,
  bordered,
  forceHeight = false,
  alt,
  tooltip,
  useSecondarySprites = false,
  children,
  className,
}: ActorIconProps): JSX.Element | null {
  const defaults = useIconDefaults().actor;
  rounded = rounded ?? defaults.rounded;
  bordered = bordered ?? defaults.bordered;
  size = size ?? defaults.size;

  const game = useGame();

  if (!game) return null;

  if (size && size > 36 && game.actorsLargeIconsPath) {
    return (
      <Icon
        className={className}
        src={`${game.actorsLargeIconsPath}/${type}.jpg`}
        alt={alt ?? type.split('-').join(' ')}
        bordered={bordered}
        rounded={rounded}
        forceHeight={forceHeight}
        size={size}
        tooltip={tooltip}
      >
        {children}
      </Icon>
    );
  }

  return (
    <Icon
      className={`actor-sprite-${type}${
        useSecondarySprites ? '-secondary' : ''
      } ${className ?? ''}`}
      src={useSecondarySprites ? game.actorsSecondarySprite : game.actorsSprite}
      alt={alt ?? type.split('-').join(' ')}
      bordered={bordered}
      rounded={rounded}
      forceHeight={forceHeight}
      size={size}
      sprite={true}
      tooltip={tooltip}
    >
      {children}
    </Icon>
  );
}
