import React from 'react';
import LazyLoad from 'react-lazyload';
import EncounterIcon, {
  EncounterIconProps,
} from '../../EncounterIcon/EncounterIcon';
import useEnhancedMarkdownOptions from '@resources/js/contexts/useEnhancedMarkdownOptions';

type EnhancedMarkdownEncounterIconProps = Pick<
  EncounterIconProps,
  'id' | 'children' | 'rounded' | 'bordered'
>;

export default function EnhancedMarkdownEncounterIcon({
  id,
  children,
  bordered,
  rounded,
}: EnhancedMarkdownEncounterIconProps): JSX.Element {
  const lazyLoad = useEnhancedMarkdownOptions().lazyLoad;

  const component = (
    <span className='enhanced-markdown-encounter-icon'>
      <EncounterIcon id={id} rounded={rounded} bordered={bordered}>
        {children}
      </EncounterIcon>
    </span>
  );

  if (lazyLoad) {
    return <LazyLoad style={{ display: 'inline-block' }}>{component}</LazyLoad>;
  }

  return component;
}
