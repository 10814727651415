import React, { ForwardedRef, useState } from 'react';
import MaybeLink from '../MaybeLink/MaybeLink';
import Button from '../Button/Button';
import ScreenReaderOnly from '../ScreenReaderOnly/ScreenReaderOnly';
import HorizontalContent from '../HorizontalContent/HorizontalContent';
import TileErrorBoundary from '../TileErrorBoundary/TileErrorBoundary';
import LinkButton from '../LinkButton/LinkButton';
import useTheme from '../../contexts/useTheme';

export type TileAction = {
  icon?: string;
  spinning?: boolean;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  href?: string;
  style?: string;
};

export type TileProps = {
  style?: TileStyle;
  padding?: 'regular' | 'narrow' | 'none';
  title?: React.ReactNode;
  titleClassName?: string;
  titleUrl?: string;
  subtitle?: React.ReactNode;
  subtitleClassName?: string;
  subtitleUrl?: string;
  actions?: TileAction[];
  collapsible?: boolean;
  initiallyCollapsed?: boolean;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  cssStyle?: React.CSSProperties;
  contentCssStyle?: React.CSSProperties;
  required?: boolean;
  backgroundImage?: {
    url?: string;
    urls?: string[];
    style?: React.CSSProperties;
    overlayStyle?: React.CSSProperties;
  };
  fullHeight?: boolean;
  onHeaderMouseDown?: () => void;
};

export type TileStyle = 'tile' | 'sub-tile' | 'flat';

const Tile = React.forwardRef(function Tile(
  props: TileProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    padding = 'regular',
    title,
    titleClassName = '',
    titleUrl,
    subtitle,
    subtitleClassName = '',
    subtitleUrl,
    actions,
    collapsible,
    initiallyCollapsed,
    children,
    className,
    contentClassName = '',
    cssStyle,
    contentCssStyle,
    required,
    backgroundImage,
    fullHeight,
    onHeaderMouseDown = () => null,
  } = props;
  const theme = useTheme();
  const style = props.style ?? theme.tileStyle;
  const styleCssClass = style === 'tile' ? '' : 'react-tile--' + style;
  const requiredCssClass = required ? 'react-tile--required-glow' : '';
  const contentCssClass = `${contentClassName} react-tile__content--${padding}`;

  const [collapsed, setCollapsed] = useState(initiallyCollapsed);
  const toggleCollapse = () => {
    if (!collapsible) return;

    setCollapsed(!collapsed);
  };

  const component = (
    <div
      className={`react-tile ${styleCssClass} ${requiredCssClass} ${
        className ? className : ''
      } ${backgroundImage ? 'react-tile--with-background-image' : ''}`}
      style={{ ...(fullHeight ? { height: '100%' } : {}), ...cssStyle }}
      ref={ref}
    >
      {backgroundImage && (
        <div
          className={'react-tile__background-image'}
          style={{
            backgroundImage: (backgroundImage.url
              ? [backgroundImage.url]
              : backgroundImage.urls
            )
              ?.map((url) => `url('${url}')`)
              .join(', '),
            ...backgroundImage.style,
          }}
        />
      )}
      {backgroundImage?.overlayStyle && (
        <div
          className={'react-tile__background-image-overlay'}
          style={backgroundImage.overlayStyle}
        />
      )}
      {(title || actions) && (
        <div
          className={`react-tile__header ${
            padding ? `react-tile__header--padding-${padding}` : ''
          } ${collapsible ? 'react-tile__header--collapsible' : ''} ${
            collapsed ? 'react-tile__header--collapsed' : ''
          }`}
          onClick={toggleCollapse}
          onMouseDownCapture={onHeaderMouseDown}
        >
          <div
            className={'react-tile__header-left'}
            style={actions ? {} : { width: '100%' }}
          >
            {collapsible && (
              <Button
                styles={['unstyled']}
                onClick={toggleCollapse}
                className={'react-tile__collapse-icon-button'}
              >
                <span
                  className={`react-tile__collapse-icon  ${
                    collapsed ? 'react-tile__collapse-icon--collapsed' : ''
                  } ${
                    subtitle ? 'react-tile__collapse-icon--with-subtitle' : ''
                  } zmdi zmdi-chevron-up`}
                />
                <ScreenReaderOnly>
                  {trans('global.toggle_visibility')}
                </ScreenReaderOnly>
              </Button>
            )}
            <div style={actions ? {} : { width: '100%' }}>
              {title && (
                <MaybeLink href={titleUrl}>
                  <div
                    className={`react-tile__title ${titleClassName} ${
                      subtitle ? 'react-tile__title--with-subtitle' : ''
                    }`}
                    style={actions ? {} : { width: '100%' }}
                  >
                    {title}
                    {required ? ` (${trans('global.required')})` : ''}
                  </div>
                </MaybeLink>
              )}
              {subtitle && (
                <MaybeLink href={subtitleUrl}>
                  <div className={`react-tile__subtitle ${subtitleClassName}`}>
                    {subtitle}
                  </div>
                </MaybeLink>
              )}
            </div>
          </div>
          {actions && (
            <HorizontalContent gap={'small'}>
              {actions.map((action) => {
                const ActionTag = action.href
                  ? action.style === 'button'
                    ? LinkButton
                    : 'a'
                  : 'button';
                return (
                  <ActionTag
                    key={`${action.icon}${action.label}`}
                    onClick={action.onClick}
                    className={
                      `react-tile__action` +
                      (action.disabled ? ' react-tile__action--disabled' : '') +
                      (action.style !== undefined
                        ? ` react-tile__action--${action.style}`
                        : ' react-tile__action--regular')
                    }
                    href={action.disabled ? undefined : action.href}
                    disabled={action.disabled}
                  >
                    {action.icon && (
                      <span
                        className={`zmdi zmdi-${action.icon} ${
                          action.spinning ? 'zmdi-hc-spin' : ''
                        }`}
                      />
                    )}
                    {action.label && (
                      <span className={'react-tile__action-label'}>
                        {action.label}
                      </span>
                    )}
                  </ActionTag>
                );
              })}
            </HorizontalContent>
          )}
        </div>
      )}
      <div
        hidden={collapsed}
        className={'react-tile__content ' + contentCssClass}
        style={{
          ...(fullHeight ? { height: '100%', boxSizing: 'border-box' } : {}),
          ...contentCssStyle,
        }}
      >
        {children}
      </div>
    </div>
  );
  return (
    <TileErrorBoundary {...{ ...props, actions: [] }}>
      {component}
    </TileErrorBoundary>
  );
});

export default Tile;
