import React from 'react';
import PropTypes from 'prop-types';

export default function EnhancedMarkdownZmdiIcon(props) {
  return <span className={`zmdi zmdi-${props.type}`} />;
}

EnhancedMarkdownZmdiIcon.propTypes = {
  type: PropTypes.string.isRequired,
};
