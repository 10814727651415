import { useEffect, useState } from 'react';

function useStateThatChangesAfterTimeout<T>(
  initialState: T,
  timeoutDuration: number,
  newState: T
): T {
  const [state, setState] = useState(
    timeoutDuration === 0 && newState ? newState : initialState
  );

  useEffect(() => {
    if (timeoutDuration > 0) {
      const timeoutId = setTimeout(() => {
        setState(newState);
      }, timeoutDuration);

      return () => clearTimeout(timeoutId);
    }
  }, [newState, timeoutDuration]);

  return state;
}

export default useStateThatChangesAfterTimeout;
