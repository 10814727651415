// https://stackoverflow.com/a/326076
export default function (): boolean {
  if (typeof window === 'undefined') {
    // SSR
    return false;
  }
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
