import React from 'react';
import PropTypes from 'prop-types';

function LoadingSpinner({ label, inline = false, fontSize = 36 }) {
  return (
    <div
      className={'loading-spinner'}
      style={{ display: inline ? 'inline-block' : 'block' }}
    >
      {label && <p>{label}</p>}
      <div
        className='loading-spinner__icon zmdi zmdi-spinner zmdi-hc-spin'
        style={{ fontSize }}
      />
    </div>
  );
}
LoadingSpinner.propTypes = {
  label: PropTypes.string,
  inline: PropTypes.bool,
  fontSize: PropTypes.number,
};

export default LoadingSpinner;
