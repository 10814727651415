import React from 'react';
import PropTypes from 'prop-types';

export function buildEnhancedMarkdownArrowShortHands() {
  return {
    Up: () => <EnhancedMarkdownArrow direction={'up'} />,
    Down: () => <EnhancedMarkdownArrow direction={'down'} />,
    Left: () => <EnhancedMarkdownArrow direction={'left'} />,
    Right: () => <EnhancedMarkdownArrow direction={'right'} />,
  };
}

function EnhancedMarkdownArrow({ direction }) {
  switch (direction) {
    case 'up':
      return ' ↑ ';
    case 'down':
      return ' ↓ ';
    case 'left':
      return ' ← ';
    case 'right':
      return ' → ';
    default:
      return null;
  }
}
EnhancedMarkdownArrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

export default EnhancedMarkdownArrow;
