import React from 'react';
import ItemSet, { ItemSetProps } from '../../ItemSet/ItemSet';

type EnhancedMarkdownItemSetProps = ItemSetProps;

export default function EnhancedMarkdownItemSet(
  props: EnhancedMarkdownItemSetProps
): JSX.Element {
  return <ItemSet {...props} />;
}
