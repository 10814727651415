import React from 'react';
import AbilityIcon from '../../AbilityIcon/AbilityIcon';
import { normalizeType } from './EnhancedMarkdownStyled';
import LazyLoad from 'react-lazyload';
import { normalizeAbilityType, School } from '../helpers/abilitySchools';
import useEnhancedMarkdownOptions from '@resources/js/contexts/useEnhancedMarkdownOptions';

type EnhancedMarkdownAbilityIconProps = {
  id: number;
  icon: string;
  children?: string;
  type?: School;
  rounded?: boolean;
  isRtl?: boolean;
};

export default function EnhancedMarkdownAbilityIcon({
  id,
  icon,
  children,
  type,
  rounded = false,
  isRtl = false,
}: EnhancedMarkdownAbilityIconProps): JSX.Element {
  const lazyLoad = useEnhancedMarkdownOptions().lazyLoad;

  const component = (
    <span className='enhanced-markdown-ability-icon'>
      <AbilityIcon id={id} icon={icon} rounded={rounded} isRtl={isRtl}>
        {children && (
          <span className={normalizeType(normalizeAbilityType(type))}>
            {children}
          </span>
        )}
      </AbilityIcon>
    </span>
  );

  return lazyLoad ? (
    <LazyLoad style={{ display: 'inline-block' }}>{component}</LazyLoad>
  ) : (
    <>{component}</>
  );
}
