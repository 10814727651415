import React, { useCallback } from 'react';
import AsyncWithSpinnerAndErrorBox, {
  AsyncWithSpinnerAndErrorBoxProps,
} from '../AsyncWithSpinnerAndErrorBox/AsyncWithSpinnerAndErrorBox';
import useSignedUrls from '../../contexts/useSignedUrls';
import { maybePrefixForTest } from '@resources/js/hooks/useFetch';

function usePromiseFn<T>(
  url: string | null,
  onHttpSuccess?: (data: T) => void
) {
  return useCallback(
    async (_, { signal }) => {
      if (!url) {
        return;
      }

      const response = await fetch(maybePrefixForTest(url), { signal });

      if (!response.ok) throw new Error(response.statusText);

      const content =
        response.status === 204 ? undefined : await response.json();

      onHttpSuccess?.(content);

      return content;
    },
    [onHttpSuccess, url]
  );
}

type AsyncFetchWithSpinnerAndErrorBoxProps<T> = {
  url: string;
  onHttpSuccess?: (data: T) => void;
} & Pick<
  AsyncWithSpinnerAndErrorBoxProps<T>,
  | 'lazyChildren'
  | 'pendingOrRejectedHeight'
  | 'LoadingState'
  | 'initialRenderDelayWhileLoading'
>;

function AsyncFetchWithSpinnerAndErrorBox<T>({
  url,
  lazyChildren,
  onHttpSuccess,
  pendingOrRejectedHeight,
  LoadingState,
  initialRenderDelayWhileLoading,
}: AsyncFetchWithSpinnerAndErrorBoxProps<T>): JSX.Element | null {
  const signedUrls = useSignedUrls();
  const signedUrl = signedUrls?.find((x) => x.originalUrl === url)?.signedUrl;
  const finalUrl = signedUrl ? signedUrl : url;

  const promiseFn = usePromiseFn<T>(finalUrl, onHttpSuccess);

  return (
    <AsyncWithSpinnerAndErrorBox<T>
      pendingOrRejectedHeight={pendingOrRejectedHeight}
      promiseFn={promiseFn}
      lazyChildren={lazyChildren}
      LoadingState={LoadingState}
      initialRenderDelayWhileLoading={initialRenderDelayWhileLoading}
    />
  );
}

export default AsyncFetchWithSpinnerAndErrorBox;
