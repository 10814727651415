import { useContext, createContext } from 'react';

type SignedUrls = {
  originalUrl: string;
  signedUrl: string;
};

export const SignedUrlsContext = createContext<SignedUrls[]>([]);

const useSignedUrls = (): SignedUrls[] => useContext(SignedUrlsContext);

export default useSignedUrls;
