import React, { ReactNode } from 'react';
import ActorIcon, { ActorIconProps } from '../../ActorIcon/ActorIcon';
import EnhancedMarkdownStyled from './EnhancedMarkdownStyled';
import useEnhancedMarkdownOptions from '@resources/js/contexts/useEnhancedMarkdownOptions';
import LazyLoad from 'react-lazyload';

type EnhancedMarkdownActorIconProps = ActorIconProps & {
  children?: ReactNode;
};

export default function EnhancedMarkdownActorIcon(
  props: EnhancedMarkdownActorIconProps
): JSX.Element {
  const lazyLoad = useEnhancedMarkdownOptions().lazyLoad;

  // @ts-expect-error props.type should always be string but this code is probably here for a reason
  const type = props.type === true || !props.type ? '' : props.type;

  let innerComponent = <ActorIcon {...props} type={type} />;

  if (props.children) {
    const styleType = type.split('-')[0];

    innerComponent = (
      <span
        className={'enhanced-markdown-actor-icon'}
        style={{ display: 'inline-block' }}
      >
        <ActorIcon {...props} type={type}>
          <EnhancedMarkdownStyled type={styleType}>
            {props.children}
          </EnhancedMarkdownStyled>
        </ActorIcon>
      </span>
    );
  }

  const component = (
    <span className={'enhanced-markdown-actor-icon'}>{innerComponent}</span>
  );

  if (lazyLoad) {
    return <LazyLoad style={{ display: 'inline-block' }}>{component}</LazyLoad>;
  }

  return component;
}
