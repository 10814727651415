import React, { Fragment, ReactNode, useMemo } from 'react';
import Tooltip from '@resources/js/components/Tooltip/Tooltip';
import EnhancedMarkdown from '@resources/js/components/EnhancedMarkdown/EnhancedMarkdown';

export default function EnhancedMarkdownTooltip({
  content,
  children,
  width,
}: {
  content: string;
  width?: string;
  children: ReactNode;
}): JSX.Element {
  const contentNode = useMemo(() => {
    let contentNode = <Fragment>{content}</Fragment>;

    // markdown-to-jsx doesn't support passing markdown as props to markdown components
    // so we pass down escaped markdown instead and then unescape it in components that expect it
    const isEnhancedMarkdown = content.includes('&lt;');
    if (isEnhancedMarkdown) {
      contentNode = (
        <EnhancedMarkdown
          markdown={content.split('&lt;').join('<').split('&gt;').join('>')}
        />
      );
    }

    return contentNode;
  }, [content]);

  return (
    <Tooltip
      content={
        <div style={{ width: width ? parseInt(width) : undefined }}>
          {contentNode}
        </div>
      }
      zIndex={2}
    >
      {children}
    </Tooltip>
  );
}
