import React from 'react';
import { normalizeType } from './EnhancedMarkdownStyled';
import LazyLoad from 'react-lazyload';
import ItemIcon, { ItemIconProps } from '../../ItemIcon/ItemIcon';
import useEnhancedMarkdownOptions from '@resources/js/contexts/useEnhancedMarkdownOptions';

type EnhancedMarkdownItemIconProps = {
  children?: string;
  type?:
    | 'Artifact'
    | 'Legendary'
    | 'Epic'
    | 'Rare'
    | 'Uncommon'
    | 'Common'
    | 'CommonWhite'
    | number;
  id: number | string;
} & Pick<
  ItemIconProps,
  'icon' | 'size' | 'extraInfo' | 'rounded' | 'bordered' | 'isRtl'
>;

const itemRarityMap: Record<
  number,
  NonNullable<Exclude<EnhancedMarkdownItemIconProps['type'], number>>
> = {
  1: 'CommonWhite',
  2: 'Uncommon',
  3: 'Rare',
  4: 'Epic',
  5: 'Legendary',
  6: 'Artifact',
};

export const normalizeItemType = (type?: number | string): string => {
  if (!type) {
    return 'CommonWhite';
  }

  if (typeof type === 'number') {
    // already is a number from direct props
    return itemRarityMap[type] ?? 'CommonWhite';
  }

  // might still be a number via markdown props; will be stringified
  const asNumber = Number.parseInt(type);

  if (!Number.isNaN(asNumber)) {
    return itemRarityMap[asNumber] ?? 'CommonWhite';
  }

  return type;
};

export default function EnhancedMarkdownItemIcon({
  id,
  icon,
  size,
  children,
  extraInfo,
  bordered,
  rounded,
  type,
  isRtl,
}: EnhancedMarkdownItemIconProps): JSX.Element {
  const lazyLoad = useEnhancedMarkdownOptions().lazyLoad;

  const component = (
    <span className='enhanced-markdown-item-icon'>
      <ItemIcon
        id={Number(id)}
        icon={icon}
        size={size}
        extraInfo={extraInfo}
        rounded={rounded}
        bordered={bordered}
        isRtl={isRtl}
      >
        {children && (
          <span className={normalizeType(normalizeItemType(type))}>
            {children}
          </span>
        )}
      </ItemIcon>
    </span>
  );

  if (lazyLoad) {
    return <LazyLoad style={{ display: 'inline-block' }}>{component}</LazyLoad>;
  }

  return component;
}
