/**
 * Our core `Icon` component with common styles. Touching this component has a HUGE splash radius,
 * so be very careful about it! If you have to modify it, make sure to do a wide sweep to re-check
 * ActorIcon, ItemIcon, etc
 *
 * @module
 */
import React, {
  CSSProperties,
  useLayoutEffect,
  useRef,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react';
import Tooltip, { TooltipProps } from '../Tooltip/Tooltip';
import useGame from '../../contexts/useGame';
import useIconDefaults from '../../contexts/useIconDefaults';
import useTheme from '@resources/js/contexts/useTheme';

export type IconProps = {
  className?: string;
  src?: string;
  alt?: string;
  size?: number;
  rounded?: boolean;
  bordered?: boolean;
  sprite?: boolean;
  /*
   * `forceHeight` defaults to `false`, which means the icon technically has 0 height, which allows the icon to be
   * properly vertically centered at different sizes and alongside text. However, if there is no label or immediately
   * surrounding text for the icon to piggyback height from, `forceHeight` should be set to `true`.
   */
  forceHeight?: boolean;
  cssStyle?: CSSProperties;
  tooltip?: Omit<TooltipProps, 'children'>;
  children?: ReactNode;
  isRtl?: boolean;
  style?: IconStyle;
};

export type IconStyle = 'v1' | 'v2';

export default function Icon(props: IconProps): JSX.Element | null {
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return (
      <span
        className={props.className}
        style={{
          display: 'inline-block',
          ...props.cssStyle,
          width: `${props.size}px`,
          height: `${props.size}px`,
        }}
      />
    );
  }

  return <ClientIcon {...props} />;
}

function ClientIcon({
  className = '',
  src,
  alt = '',
  size,
  rounded,
  bordered,
  sprite = false,
  forceHeight = false,
  cssStyle = {},
  tooltip,
  children,
  isRtl,
  style,
}: IconProps): JSX.Element | null {
  const defaults = useIconDefaults().icon;
  rounded = rounded ?? defaults.rounded;
  bordered = bordered ?? defaults.bordered;
  size = size ?? defaults.size;

  const game = useGame();
  const theme = useTheme();
  style = style ?? theme.iconStyle;
  const ref = useRef<HTMLSpanElement | null>(null);

  useLayoutEffect(() => {
    ref.current?.style.setProperty('--size', `${size}`);
  }, [size]);

  const srcIsAbilityFileName =
    !src?.startsWith('http') && !src?.startsWith('/');
  if (src && srcIsAbilityFileName) {
    src = `${game?.assetServerBaseUrl}/img/${game?.lowercaseName}/abilities/${src}`;
  }

  const child = useMemo(
    () => (
      <span
        className={`icon ${bordered ? ' icon--bordered' : ''} ${
          forceHeight ? ' icon--force-height' : ''
        } ${isRtl ? 'icon--rtl' : ''} ${
          children ? '' : 'icon--childless'
        } icon--style-${style}`}
        ref={ref}
      >
        {src && (
          <img
            className={
              `${className} icon__image` +
              (rounded ? ' icon__image--rounded' : '') +
              (sprite ? ' icon__image--sprite' : '') +
              (children ? ' icon__image--with-label' : '')
            }
            src={src}
            alt={alt}
            style={cssStyle}
            loading={'lazy'}
          />
        )}
        {children ? (
          <span
            className={`icon__label ${
              size && size >= 30 ? 'icon__label--large' : ''
            } ${!src ? 'icon__label--no-image' : ''}`}
          >
            {children}
          </span>
        ) : null}
      </span>
    ),
    [
      bordered,
      forceHeight,
      isRtl,
      children,
      style,
      className,
      rounded,
      sprite,
      src,
      alt,
      cssStyle,
      size,
    ]
  );

  if (srcIsAbilityFileName && !game) return null;

  return tooltip ? <Tooltip {...tooltip}>{child}</Tooltip> : child;
}
