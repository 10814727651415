import React from 'react';
import { normalizeType } from '../EnhancedMarkdown/components/EnhancedMarkdownStyled';
import { normalizeAbilityType } from '../EnhancedMarkdown/helpers/abilitySchools';
import Tooltip, { TooltipProps } from '../Tooltip/Tooltip';

export type BarProps = {
  type?: string;
  percent: number | string;
  height?: number;
  tooltipProps?: Omit<TooltipProps, 'kind' | 'children'>;
  opacity?: number | null;
};

export default function Bar({
  type,
  percent,
  height = 16,
  tooltipProps,
  opacity = null,
}: BarProps): JSX.Element {
  const child =
    opacity !== null ? (
      <div className={'bar__big-bar'}>
        <div
          className={`${normalizeType(normalizeAbilityType(type))}-bg`}
          style={{
            width: `${percent}%`,
            height: `${height}px`,
            opacity,
          }}
        />
        {opacity < 1 ? (
          <div
            className={`bar__small-bar ${normalizeType(
              normalizeAbilityType(type)
            )}-bg`}
            style={{
              left: 0,
              width: `${percent}%`,
              opacity: 1 - opacity,
            }}
          />
        ) : null}
      </div>
    ) : (
      <div
        className={`bar ${normalizeType(normalizeAbilityType(type))}-bg`}
        style={{ width: `${percent}%`, height: `${height}px` }}
      />
    );

  if (tooltipProps) {
    return (
      <Tooltip
        {...tooltipProps}
        placementOptions={{
          alignment: 'start',
        }}
      >
        {child}
      </Tooltip>
    );
  }

  return child;
}
