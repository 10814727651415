import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactNode,
} from 'react';
import useTheme from '../../contexts/useTheme';

export type ButtonStyle =
  | 'primary'
  | 'warning'
  | 'color-light-blue'
  | 'color-red'
  | 'icon'
  | 'unstyled'
  | 'full-size'
  | 'link'
  | 'required-border'
  | 'highlight-glow'
  | 'menu'
  | 'menu-selected'
  | 'disabled'
  | 'slim'
  | 'gradient-rounded'
  | 'full-width'
  | 'hard-borders'
  | 'bold'
  | 'uppercase'
  | 'search'
  | 'search-disabled'
  | 'body-font-size'
  | undefined;

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  children: ReactNode;
  styles?: ButtonStyle[];
};

function Button({
  children,
  onClick,
  disabled = false,
  className,
  type = 'button',
  title,
  tabIndex,
  styles,
  ...props
}: ButtonProps): JSX.Element {
  const theme = useTheme();
  styles = styles ?? theme.primaryButtonStyles;

  return (
    <button
      title={title}
      type={type}
      className={`react-button ${styles
        .filter((style) => style !== undefined)
        .map((style) => `react-button--style-${style}`)
        .join(' ')} ${className ? className : ''}`}
      onClick={onClick}
      disabled={disabled}
      tabIndex={tabIndex}
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;
