import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ErrorBox from '../../ErrorBox/ErrorBox';
import EnhancedMarkdownTile from './EnhancedMarkdownTile';
import slugify from '../../../helpers/slugify';
import Heading from '../../Heading/Heading';

function getHeading(header) {
  const headerParts = header?.split?.(' ');

  if (!headerParts?.[0]) return null;

  const headerLevel = headerParts[0];
  const headerLevelIsValid = [...headerLevel].every((x) => x === '#');

  if (!headerLevelIsValid)
    return {
      text: header,
    };

  const text = headerParts
    .slice(1)
    .join(' ')
    .split('&lt;')
    .join('<')
    .split('&gt;')
    .join('>');

  return {
    level: headerLevel.length,
    text,
    id: slugify(text),
  };
}

function EnhancedMarkdownCollapseHeader({ headerLevel, id, children }) {
  const props = {
    id,
    className: 'enhanced-markdown-collapse__header',
  };

  return (
    <Heading level={Math.min(headerLevel + 1, 6)} {...props}>
      {children}
    </Heading>
  );
}
EnhancedMarkdownCollapseHeader.propTypes = {
  headerLevel: PropTypes.number.isRequired,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function EnhancedMarkdownCollapse({
  header,
  isCollapsed: initialIsCollapsed = true,
  children,
}) {
  const [isCollapsed, setIsCollapsed] = useState(initialIsCollapsed);

  const heading = getHeading(header);

  if (!heading)
    return (
      <ErrorBox>
        <p>
          <code>header</code> must be in a valid format. For example:{' '}
          <code>## This is my header</code> or <code>This is my header</code>.
        </p>
      </ErrorBox>
    );

  if (!heading.level)
    return (
      <EnhancedMarkdownTile padding={'narrow'}>
        <details open={!isCollapsed}>
          <summary>{header}</summary>
          {children}
        </details>
      </EnhancedMarkdownTile>
    );

  return (
    <>
      <EnhancedMarkdownCollapseHeader
        headerLevel={heading.level}
        id={heading.id}
      >
        <button
          className={`enhanced-markdown-collapse__button ${
            isCollapsed ? '' : 'enhanced-markdown-collapse__button--down'
          }`}
          onClick={() => setIsCollapsed((collapsed) => !collapsed)}
        >
          &#8250;
        </button>
        <span
          className={'enhanced-markdown-collapse__header-text'}
          onClick={() => setIsCollapsed((collapsed) => !collapsed)}
        >
          {heading.text}
        </span>
      </EnhancedMarkdownCollapseHeader>
      {isCollapsed ? (
        <div style={{ display: 'none' }}>{children}</div>
      ) : (
        children
      )}
    </>
  );
}
EnhancedMarkdownCollapse.propTypes = {
  header: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

EnhancedMarkdownCollapse.getHeading = (props) => {
  const heading = getHeading(props.header);

  if (!heading?.level) return null;

  return heading;
};

export default EnhancedMarkdownCollapse;
