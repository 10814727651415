import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import * as PropTypes from 'prop-types';
import HorizontalRule from '../HorizontalRule/HorizontalRule';
import EnhancedMarkdown from '../EnhancedMarkdown/EnhancedMarkdown';
import isInIframe from '../../helpers/isInIframe';
import Heading from '../Heading/Heading';
import Body from '../Body/Body';

function Markdown({
  restricted = true,
  openLinksExternally = true,
  styles = [],
  children,
  before,
  after,
  className,
  enhanced = false,
  enhancedOverrides,
}) {
  const isNotIframe = !isInIframe();
  const renderers = {};
  renderers.thematicBreak = () => <HorizontalRule />;
  if (openLinksExternally) {
    renderers.link = (x) => (
      <a
        href={x.href}
        {...(isNotIframe
          ? { target: '_blank', rel: 'noopener noreferrer' }
          : {})}
      >
        {x.children}
      </a>
    );
  }
  renderers.heading = (x) => (
    <Heading level={restricted ? (x.level === 1 ? 2 : x.level) : x.level}>
      {x.children}
    </Heading>
  );

  return (
    <div
      className={`markdown ${className ? className : ''} ${styles
        .map((style) => `markdown--styles-${style}`)
        .join(' ')}`}
    >
      {before}
      <Body className={`markdown__content`}>
        {enhanced ? (
          <EnhancedMarkdown
            markdown={children}
            wrapped={false}
            overrides={enhancedOverrides}
          />
        ) : (
          <ReactMarkdown
            plugins={[gfm]}
            disallowedTypes={
              restricted ? ['html', 'image', 'imageReference'] : []
            }
            renderers={renderers}
          >
            {children}
          </ReactMarkdown>
        )}
      </Body>
      {after}
    </div>
  );
}
Markdown.propTypes = {
  restricted: PropTypes.bool,
  openLinksExternally: PropTypes.bool,
  children: PropTypes.node,
  before: PropTypes.node,
  after: PropTypes.node,
  styles: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  enhanced: PropTypes.bool,
  enhancedOverrides: PropTypes.object,
};

export default Markdown;
