import React, { useContext, ReactNode, createContext } from 'react';

type EnhancedMarkdownOptionsContext = {
  lazyLoad: boolean;
};

export const EnhancedMarkdownOptionsContext =
  createContext<EnhancedMarkdownOptionsContext>({
    lazyLoad: true,
  });

const useEnhancedMarkdownOptions = (): EnhancedMarkdownOptionsContext =>
  useContext(EnhancedMarkdownOptionsContext);

export const EnhancedMarkdownOptionsContextProvider = ({
  value,
  children,
}: {
  value: EnhancedMarkdownOptionsContext;
  children: ReactNode;
}): JSX.Element => {
  return (
    <EnhancedMarkdownOptionsContext.Provider value={value}>
      {children}
    </EnhancedMarkdownOptionsContext.Provider>
  );
};

export default useEnhancedMarkdownOptions;
