import React from 'react';
import PropTypes from 'prop-types';
import useGame from '../../../contexts/useGame';

function EnhancedMarkdownDiscordInviteLink({ children }) {
  const game = useGame();
  const inviteLink = `https://www.${game.lowercaseName}logs.com/join-discord`;

  return (
    <a href={inviteLink} target={'_blank'} rel={'noreferrer noopener'}>
      {children ?? 'Discord'}
    </a>
  );
}
EnhancedMarkdownDiscordInviteLink.propTypes = {
  children: PropTypes.node,
};

export default EnhancedMarkdownDiscordInviteLink;
