import React, { useContext, ReactNode, createContext } from 'react';
import { GameViewModel } from '../typings/viewModels/gameViewModel';

export const GameContext = createContext<GameViewModel | null>(null);

const useGame = (): GameViewModel | null => useContext(GameContext);

export const GameContextProvider = ({
  game,
  children,
}: {
  game: GameViewModel;
  children: ReactNode;
}): JSX.Element => (
  <GameContext.Provider value={game}>{children}</GameContext.Provider>
);

export default useGame;
