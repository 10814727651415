import React, { ReactNode } from 'react';

export type BadgeLabelProps = {
  children: ReactNode;
  style?: 'gray' | 'dark-gray' | 'red' | 'orange' | 'green' | 'level-1';
};

function BadgeLabel({
  children,
  style = 'gray',
}: BadgeLabelProps): JSX.Element {
  return (
    <span className={`badge-label badge-label--${style}`}>{children}</span>
  );
}

export default BadgeLabel;
