import React, { ReactNode } from 'react';
import BadgeLabel, {
  BadgeLabelProps,
} from '@resources/js/components/BadgeLabel/BadgeLabel';

export default function EnhancedMarkdownBadgeLabel({
  children,
  _style,
}: {
  children: ReactNode;
  _style?: BadgeLabelProps['style'];
}): JSX.Element {
  return <BadgeLabel style={_style ?? 'level-1'}>{children}</BadgeLabel>;
}
